// Styles inherited from style sheets will not be rendered for elements with these tag names
// var noStyleTags = {"BASE":true,"HEAD":true,"HTML":true,"META":true,"NOFRAME":true,"NOSCRIPT":true,"PARAM":true,"SCRIPT":true,"STYLE":true,"TITLE":true};
var noStyleTags = {"BASE":true,"HEAD":true,"HTML":true,"META":true,"NOFRAME":true,"NOSCRIPT":true,"PARAM":true,"SCRIPT":true,"STYLE":true,"TITLE":true};
var noDisplayTags = ["STYLE", "SCRIPT", "HEAD", "TITLE"];

// This list determines which css default values lookup tables are precomputed at load time
// Lookup tables for other tag names will be automatically built at runtime if needed
var tagNames = ["A","ABBR","ADDRESS","AREA","ARTICLE","ASIDE","AUDIO","B","BDI","BDO","BLOCKQUOTE","BODY","BR","BUTTON","CANVAS","CAPTION","CENTER","CITE","CODE","COL","COLGROUP","COMMAND","DATALIST","DD","DEL","DETAILS","DFN","DIV","DL","DT","EM","EMBED","FIELDSET","FIGCAPTION","FIGURE","FONT","FOOTER","FORM","H1","H2","H3","H4","H5","H6","HEADER","HGROUP","HR","I","IFRAME","IMG","INPUT","INS","KBD","KEYGEN","LABEL","LEGEND","LI","LINK","MAP","MARK","MATH","MENU","META","METER","NAV","NOBR","OBJECT","OL","OPTION","OPTGROUP","OUTPUT","P","PRE","PROGRESS","Q","RP","RT","RUBY","S","SAMP","SECTION","SELECT","SMALL","SOURCE","SPAN","STRONG","SUB","SUMMARY","SUP","SVG","TABLE","TBODY","TD","TEXTAREA","TFOOT","TH","THEAD","TIME","TR","TRACK","U","UL","VAR","VIDEO","WBR"];

// From: http://stackoverflow.com/questions/6209161/extract-the-current-dom-and-print-it-as-a-string-with-styles-intact
var addSerializeWithStyles = function() {

var ignoredAttributes = ["class", "id", "name", "data-ember-extension", "data-ember-action", "download"];
var disallowedTags = ["SCRIPT", "STYLE"];
var unimportantTags = ["cursor", ];
var ignoreDefaultProperties = ["height", "width"];

var getDefaultStyles = function(){
    var defaultStyles = {},
        element = document.createElement("div"),
        styles;
    element = document.body.appendChild(element);
    styles = getComputedStyle(element);
    for (var i = 0; i < styles.length; i++){
        if(!ignoreDefaultProperties.includes(styles[i])){
            defaultStyles[styles[i]] = styles[styles[i]];
        }
    }
    document.body.removeChild(element);
    return defaultStyles;
};

Element.prototype.serializeWithStyles = (function() {
    return function serializeWithStyles() {
        if (this.nodeType !== Node.ELEMENT_NODE) { throw new TypeError(); }
        var cssTexts = [],
            querySelector = "*",
            elements = this.querySelectorAll(querySelector),
            defaultStyles = getDefaultStyles();

        for (var i=0; i <elements.length; i++) {
            var e = elements[i];
            var styleString = "";

            if (!noStyleTags[e.tagName]) {
                var computedStyle = getComputedStyle(e);
                for (var ii=0; ii < computedStyle.length; ii++) {
                    var cssPropName = computedStyle[ii];
                    if (computedStyle[cssPropName] !== defaultStyles[cssPropName] &&
                            !unimportantTags.includes(cssPropName)) {
                        let value = computedStyle[cssPropName].replace(/[0-9]+\.[0-9]+e\-{0,1}[0-9]+/g, function(match){ return parseFloat(match).toFixed(4); });
                        styleString += [cssPropName, ":", value, ";"].join("");
                    }
                }
            }
            cssTexts[i] = styleString;
        }

        // var parentNode = elements[0].parentNode;
        var newBase = this.cloneNode(true),
            newElements = newBase.querySelectorAll(querySelector),
            result = '',
            newElement, numAttributes, elAttributes, elAttribute,
            idx, deadElements = [];

        result += "<style type='text/css'>*, ";
        result += tagNames.join(", ");
        result += "{";
        for (var prop in defaultStyles){
            if(defaultStyles.hasOwnProperty(prop)){
                result += prop + " : " + defaultStyles[prop] + "; ";
            }
        } 
        result += "} ";
        result += noDisplayTags.join(", ");
        result += " {display:none;}</style>";
        
        for (idx=0; idx < newElements.length; idx++) {
            newElement = newElements[idx];
            elAttributes = newElement.attributes;
            numAttributes = elAttributes.length;

            var newStyle = window.getComputedStyle(newElement);
            if (newStyle.getPropertyValue('visibility') === "hidden" ||
                    newStyle.getPropertyValue('display') === "none" ||
                    newElement.classList.contains("no-print") ||
                    disallowedTags.includes(newElement.tagName)) {
                deadElements.push(newElement);
                continue;
            }
            for (var attrIdx = 0; attrIdx < numAttributes; attrIdx++) {
                if (!elAttributes[attrIdx] || !elAttributes[attrIdx].name) {
                    console.warn("No Name", attrIdx, elAttributes[attrIdx], elAttributes);
                }
                elAttribute = elAttributes[attrIdx].name;
                if (ignoredAttributes.includes(elAttribute)) {
                    newElement.removeAttribute(elAttribute);
                    numAttributes--;
                }
            }
            newElement.setAttribute("style", cssTexts[idx]);
        }
        while(deadElements.length > 0){
            var deadEl = deadElements.pop();
            if (deadEl && deadEl.parentNode) {
                deadEl.parentNode.removeChild(deadEl);
            }
        }
        newElements = newBase.querySelectorAll(querySelector);
        for (idx=0; idx < newElements.length; idx++) {
            newElement = newElements[idx];

        }
        result += newBase.innerHTML;
        return result.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "");
    };
})();
};


(function(){
    if (document.readyState === "complete") {
        addSerializeWithStyles();
    } else if(window.onload) {
        var curronload = window.onload;
        var newonload = function() {
            curronload();
            addSerializeWithStyles();
        };
        window.onload = newonload;
    } else {
        window.onload = addSerializeWithStyles;
    }
})();